<template>
  <div style="min-height: 0">
    <md-snackbar
      :md-active.sync="showSnackbar"
      :md-duration="8000"
      class="snackbar"
    >
      <span>{{ snackbarText }}</span>
    </md-snackbar>
    <!-- Support Others PopUp-->
    <md-dialog :md-active.sync="showDialog0">
      <md-dialog-title>{{ getContent(1084) }}</md-dialog-title>
      <md-dialog-content>
        <div class="PopUp">
          {{ getContent(1055) }}
          <ol>
            <li>{{ getContent(1056) }}</li>
            <li>{{ getContent(1057) }}</li>
            <li>{{ getContent(1058) }}</li>
            <li>{{ getContent(1059) }}</li>
            <li>{{ getContent(1060) }}</li>
          </ol>
          {{ getContent(1061) }}
          <ol>
            <li>{{ getContent(1062) }}</li>
            <li>{{ getContent(1063) }}</li>
            <li>{{ getContent(1064) }}</li>
          </ol>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="updateDialogSettings(0)"
          >{{ getContent(1086) }}
        </md-button>
        <md-button class="md-primary" @click="showDialog0 = false"
          >{{ getContent(1085) }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Receive Support PopUp-->
    <md-dialog :md-active.sync="showDialog1">
      <md-dialog-title>{{ getContent(1084) }}</md-dialog-title>
      <md-dialog-content>
        <div class="PopUp">
          {{ getContent(1065) }}
          <ol>
            <li>{{ getContent(1066) }}</li>
            <li>{{ getContent(1067) }}</li>
            <li>{{ getContent(1068) }}</li>
            <li>{{ getContent(1069) }}</li>
          </ol>
          {{ getContent(1070) }}
          <ol>
            <li>{{ getContent(1071) }}</li>
            <li>{{ getContent(1072) }}</li>
            <li>{{ getContent(1073) }}</li>
          </ol>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="updateDialogSettings(1)"
          >{{ getContent(1086) }}
        </md-button>
        <md-button class="md-primary" @click="showDialog1 = false"
          >{{ getContent(1085) }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Mutual Support PopUp-->
    <md-dialog :md-active.sync="showDialog2">
      <md-dialog-title>{{ getContent(1084) }}</md-dialog-title>
      <md-dialog-content>
        <div class="PopUp">
          {{ getContent(1074) }}
          <ol>
            <li>{{ getContent(1075) }}</li>
            <li>{{ getContent(1076) }}</li>
            <li>{{ getContent(1077) }}</li>
            <li>{{ getContent(1078) }}</li>
          </ol>
          {{ getContent(1079) }}
          <ol>
            <li>{{ getContent(1080) }}</li>
            <li>{{ getContent(1081) }}</li>
            <li>{{ getContent(1082) }}</li>
          </ol>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="updateDialogSettings(2)"
          >{{ getContent(1086) }}
        </md-button>
        <md-button class="md-primary" @click="showDialog2 = false"
          >{{ getContent(1085) }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Report User PopUp -->
    <md-dialog-prompt
      :md-active.sync="reportUser"
      v-model="value"
      :md-title="getContent(1278)"
      :md-content="getContent(1279)"
      :md-input-placeholder="getContent(1282)"
      :md-confirm-text="getContent(1280)"
      :md-cancel-text="getContent(1281)"
      @md-confirm="reportUserFunction(value)"
    />

    <!-- Switch Support Buddies -->
    <md-dialog :md-active.sync="switchBuddies">
      <md-dialog-title>{{ getContent(1304) }}</md-dialog-title>
      <md-dialog-content>
        <div class="PopUp">
          {{ getContent(1305) }}
          <br />
          {{ getContent(1306) }}
          <br />
          <br />
          {{ getContent(1307) }}
          <br />
          {{ getContent(1308) }}
        </div>
      </md-dialog-content>
      <div v-if="switchingQuery">
        <md-progress-bar md-mode="query"></md-progress-bar>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="switchBuddies = false"
          >{{ getContent(1085) }}
        </md-button>
        <md-button class="md-primary" @click="addBuddy()"
          >{{ getContent(1309) }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Chat -->
    <div class="Desktop flexRow" v-if="!myWidth">
      <!-- Chat Desktop -->
      <div class="Selection" v-if="!myWidth">
        <div class="SelectionHeader">
          <div class="Avatar">
            <p>{{ user.name[0] }}</p>
          </div>
          <div style="font-size: 1.3em">
            {{ user.name }}
          </div>
        </div>
        <ul class="Partner">
          <li
            v-for="person in user.partners"
            v-bind:key="person.name"
            class="PartnerContact"
            v-bind:class="{ focus: focus(person.name) }"
            @click="
              focusOn = {
                id: person._id,
                name: person.name,
              };
              scrollToEnd();
            "
          >
            <span>{{ person.name }} </span>
            <span class="online" v-if="usersOnline.includes(person.name)"
              >online</span
            >
            <span class="offline" v-else>offline</span>
          </li>
          <li
            v-if="
              user &&
              user.settings.find((element) => element.name === 'supportType') &&
              user.settings.find((element) => element.name === 'supportType')
                .value === 1
            "
            @click="switchBuddies = true"
          >
            <md-icon
              class="md-size-2x PartnerContact"
              style="
                color: #000000;
                padding: 4vh;
                width: 100%;
                justify-content: center;
              "
              >person_add
            </md-icon>
          </li>
        </ul>
      </div>
      <div class="ChatWindow">
        <div class="ChatHeader">
          <div style="display: flex; align-items: center">
            <div class="Avatar">
              <p>{{ focusOn.name[0] }}</p>
            </div>
            <div style="width: 0.5vw"></div>
            <div class="ChatName">{{ focusOn.name }}</div>
          </div>
          <div class="reportUser" @click="reportUser = true">
            {{ getContent(1285) }}
          </div>
        </div>
        <div class="Chat flexColumn" ref="messagesContainer">
          <div
            v-for="msg in filterMessagesByFocus()"
            v-bind:key="msg.timestamp"
            :class="
              msg.fromUsrName === user.name
                ? 'bubble outgoing'
                : 'bubble incoming'
            "
          >
            {{ msg.text }}<br />
            <span
              :style="
                msg.fromUsrName === user.name
                  ? 'font-size: 0.8em; width: 100%; text-align: right'
                  : 'font-size: 0.8em'
              "
              >{{
                new Intl.DateTimeFormat("de", {
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(msg.timestamp))
              }}</span
            >
          </div>
          <!--        <div class="typing">-->
          <!--          <div class="bubble">-->
          <!--            <div class="ellipsis one"></div>-->
          <!--            <div class="ellipsis two"></div>-->
          <!--            <div class="ellipsis three"></div>-->
          <!--          </div>-->
          <!--        </div>-->
        </div>
        <div class="ChatMessage">
          <!-- <input
            type="text"
            :placeholder="getContent(70)"
            v-model="newMessage"
            @keyup.enter="sendPrivate"
          /> -->
          <textarea
            class="textarea"
            v-model="newMessage"
            :placeholder="getContent(70)"
            style="
              min-width: 90%;
              max-width: 90%;
              border-radius: 10px;
              font-size: 1.2em;
              resize: none;
            "
            id="ChatBox"
          ></textarea>
          <button class="button" type="submit" @click="sendPrivate">
            {{ getContent(71) }}
          </button>
        </div>
      </div>
    </div>
    <!-- Chat Mobile -->
    <div v-if="myWidth" class="mobile">
      <md-app md-mode="reveal" style="height: 80%">
        <md-app-toolbar class="md-primary" style="background: #4f83ac">
          <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
            <md-icon>menu</md-icon>
          </md-button>
          <div
            class="md-title"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 80%;
            "
          >
            <div style="display: flex; align-items: center">
              <div class="Avatar">
                <p>{{ focusOn.name[0] }}</p>
              </div>
              <div style="font-size: 1.3em; margin-left: 5px">
                {{ focusOn.name }}
              </div>
            </div>
            <div class="reportUser-mobile" @click="reportUser = true">
              {{ getContent(1286) }}
            </div>
          </div>
        </md-app-toolbar>

        <md-app-drawer :md-active.sync="menuVisible">
          <md-toolbar class="md-transparent" md-elevation="0">
            <div class="Avatar">
              <p>{{ user.name[0] }}</p>
            </div>
            <div style="font-size: 1.3em; margin-left: 10px">
              {{ user.name }}
            </div>
          </md-toolbar>

          <md-list>
            <ul class="Partner-mobile">
              <li
                v-for="person in user.partners"
                v-bind:key="person.name"
                class="PartnerContact-mobile"
                v-bind:class="{ focus: focus(person.name) }"
                v-on:click="
                  focusOn = {
                    id: person._id,
                    name: person.name,
                  };
                  scrollToEnd();
                  menuVisible = false;
                "
              >
                <md-list-item>
                  <span>{{ person.name }} </span>
                  <span
                    class="online-mobile"
                    v-if="usersOnline.includes(person.name)"
                    >online</span
                  >
                  <span class="offline-mobile" v-else>offline</span>
                </md-list-item>
              </li>
              <md-list-item
                v-if="
                  user &&
                  user.settings.find(
                    (element) => element.name === 'supportType'
                  ) &&
                  user.settings.find(
                    (element) => element.name === 'supportType'
                  ).value === 1
                "
                @click="switchBuddies = true"
              >
                <md-icon
                  class="md-size-2x PartnerContact-mobile"
                  style="
                    color: #000000;
                    width: 100%;
                    justify-content: center;
                    margin: 0;
                    border: 0;
                  "
                  >person_add
                </md-icon>
              </md-list-item>
            </ul>
          </md-list>
        </md-app-drawer>
        <md-app-content>
          <div class="Chat-mobile flexColumn" ref="messagesContainerMobile">
            <div
              v-for="msg in filterMessagesByFocus()"
              v-bind:key="msg.timestamp"
              :class="
                msg.fromUsrName === user.name
                  ? 'bubble outgoing'
                  : 'bubble incoming'
              "
            >
              {{ msg.text }}<br />
              <span
                :style="
                  msg.fromUsrName === user.name
                    ? 'font-size: 0.8em; width: 100%; text-align: right'
                    : 'font-size: 0.8em'
                "
                >{{
                  new Intl.DateTimeFormat("de", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(msg.timestamp))
                }}</span
              >
            </div>
            <!--        <div class="typing">-->
            <!--          <div class="bubble">-->
            <!--            <div class="ellipsis one"></div>-->
            <!--            <div class="ellipsis two"></div>-->
            <!--            <div class="ellipsis three"></div>-->
            <!--          </div>-->
            <!--        </div>-->
          </div>
        </md-app-content>
      </md-app>
      <div style="height: 0.5%"></div>
      <div class="ChatMessage-mobile" v-if="!menuVisible">
        <!-- <input
          type="text"
          :placeholder="getContent(70)"
          v-model="newMessage"
          @keyup.enter="sendPrivate"
        /> -->
        <textarea
          class="textarea"
          v-model="newMessage"
          :placeholder="getContent(70)"
          style="
            min-width: 80%;
            max-width: 80%;
            border-radius: 10px;
            font-size: 1.2em;
            resize: none;
          "
          id="ChatBox"
        ></textarea>
        <button class="button" type="submit" @click="sendPrivate">
          {{ getContent(71) }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { loadData } from "@/utils";
import store from "@/store";
import CryptoJS from "crypto-js";
import { axiosGet, axiosPost } from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      isConnected: false,
      socketMessage: "",

      user: {
        name: "",
      },
      userId: store.state.accessToken,

      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),

      text: {},
      myWidth: screen.height / screen.width > 1,

      // Functional Variables
      focusOn: {
        id: "",
        name: "",
      },
      value: "",
      newMessage: "",
      messages: [],
      showDialog0: false,
      showDialog1: false,
      showDialog2: false,
      setting: 1,
      usersOnline: [],
      menuVisible: false,
      reportUser: false,
      switchBuddies: false,
      switchingQuery: false,
      showSnackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    focus: function (name) {
      return name === this.focusOn.name;
    },
    reportUserFunction(text) {
      axiosPost("/report", {
        userMail: this.user.name,
        reportedMail: this.focusOn.name,
        reason: text,
        userID: this.user._id,
        reportedID: this.focusOn.id,
      })
        .then(() => this.$router.go(0))
        .catch((err) => console.warn(err));
    },
    filterMessagesByFocus: function () {
      let showMsg = (message) =>
        message.fromUsrName === this.focusOn.name ||
        message.toUsrName === this.focusOn.name ||
        message.fromUsrName === "MindBot";
      return this.messages.filter(showMsg);
    },
    sendPrivate: async function () {
      if (["", " ", "  ", "   "].includes(this.newMessage)) return;
      //save Meassge
      const msgDataEncrypted = {
        text: CryptoJS.AES.encrypt(this.newMessage, this.focusOn.id).toString(),
        toUsrName: this.focusOn.name,
        fromUsrName: this.user.name,
        timestamp: Date.now(),
      };
      const msgData = {
        text: this.newMessage,
        toUsrName: this.focusOn.name,
        fromUsrName: this.user.name,
        timestamp: Date.now(),
      };

      this.newMessage = "";
      this.messages.push(msgData);
      await this.$socket.emit("privateMessage", msgDataEncrypted);
      this.scrollToEnd();
    },
    loadData() {
      return axiosGet("/users/id/" + this.userId)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.warn(error);
        });
    },
    getContent: function (key) {
      return this.text[key];
    },
    fillAndEncryptMessages: function () {
      let allMsg = this.user.chat;
      allMsg = allMsg.concat(
        this.user.partners.map((p) => p.chat).reduce((a, b) => [...a, ...b], [])
      );
      let encryptedMsg = [];
      encryptedMsg = allMsg
        .filter((m) => m.toUsrName === this.user.name)
        .map((m) => {
          m.text = CryptoJS.AES.decrypt(
            m.text,
            this.user._id.toString()
          ).toString(CryptoJS.enc.Utf8);
          return m;
        });
      encryptedMsg = encryptedMsg.concat(
        allMsg
          .filter((m) => m.fromUsrName === this.user.name)
          .map((m) => {
            const msgFrom = this.user.partners.find(
              (p) => p.name === m.toUsrName
            );
            if (msgFrom) {
              m.text = CryptoJS.AES.decrypt(
                m.text,
                msgFrom._id.toString()
              ).toString(CryptoJS.enc.Utf8);
            } else {
              console.warn("Message from undefined user. Can not encrypt.");
              return m;
            }
            return m;
          })
      );
      this.messages = [...encryptedMsg, ...this.messages].sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );
      this.scrollToEnd();
    },
    scrollToEnd: function () {
      const content = this.myWidth
        ? this.$refs.messagesContainerMobile
        : this.$refs.messagesContainer;
      setTimeout(() => {
        content.scrollTop = content.scrollHeight - content.clientHeight;
      }, 0);
    },
    computePopups: function () {
      if (
        this.user.settings.filter((e) => e.name === "supportType").length > 0
      ) {
        const supportType = this.user.settings.filter(
          (e) => e.name === "supportType"
        )[0].value;
        if (
          this.user.settings.filter((e) => e.name === "chatPopup").length === 0
        )
          switch (supportType) {
            case 0:
              this.showDialog0 = true;
              break;
            case 1:
              this.showDialog1 = true;
              break;
            case 2:
              this.showDialog2 = true;
              break;
          }
      }
    },
    updateDialogSettings: function (type) {
      switch (type) {
        case 0:
          this.showDialog0 = false;
          break;
        case 1:
          this.showDialog1 = false;
          break;
        case 2:
          this.showDialog2 = false;
          break;
      }
      axiosPost("/users/settings/" + this.userId, {
        name: "chatPopup",
      });
    },
    addBuddy: async function () {
      this.switchingQuery = true;
      try {
        const potentialBuddies = (
          await axiosGet("/matching/switch/" + this.userId)
        ).data.buddies;
        console.log(potentialBuddies);
        if (potentialBuddies.length === 0)
          this.snackbarText = this.getContent(1310);
        else {
          await axiosPost("/matching/applySwitch/", {
            userID: this.userId,
            buddyID: potentialBuddies[0]._id,
          });
          this.snackbarText = this.getContent(1311);
          this.user = await this.loadData();
          this.focusOn = {
            id: this.user.partners[0]._id,
            name: this.user.partners[0].name,
          };
          (this.messages = []), this.fillAndEncryptMessages();
        }
      } catch (e) {
        console.warn(e);
        this.snackbarText = this.getContent(1312);
      } finally {
        this.switchingQuery = false;
        this.switchBuddies = false;
        this.showSnackbar = true;
      }
    },
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },
  async mounted() {
    this.text = await loadData("Chat", this.myLanguage);
    this.user = await this.loadData();

    await this.computePopups();
    await this.fillAndEncryptMessages();

    let partners = this.user.partners.map((x) => ({
      id: x._id,
      name: x.name,
    }));
    await this.$socket.emit("join", this.user.name, partners);
    await this.sockets.subscribe("message", async function (data) {
      data.text = CryptoJS.AES.decrypt(
        data.text,
        this.user._id.toString()
      ).toString(CryptoJS.enc.Utf8);
      await this.messages.push(data);
      this.scrollToEnd();
    });
    await this.sockets.subscribe("usersOnline", function (data) {
      this.usersOnline = data;
    });

    this.focusOn = {
      id: partners[0]._id,
      name: partners[0].name,
    };
  },

  module: {
    rules: [
      {
        test: /\.scss$/,
        use: ["vue-style-loader", "css-loader", "sass-loader"],
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
.Desktop {
  background: linear-gradient(to bottom left, #009688 40%, #ffffff 100%);
  width: 100%;
  height: 800px;
  justify-content: center;
}

.mobile {
  background: #009688;
  width: 100%;
  height: 100%;
}

.Selection {
  margin: 6%;
  margin-right: 0%;
  width: 30%;
  height: 76%;
  border-radius: 15px 0 0 15px;
  box-shadow: -5px 5px 10px;
  color: #000000;
}

.Selection-mobile {
  width: 100%;
  height: 30%;
  color: #000000;
}

.SelectionHeader {
  height: 30%;
  opacity: 0.4;
  background: #ffffff;
  border-radius: 15px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
}

.SelectionHeader-mobile {
  height: 30%;
  opacity: 0.4;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
}

.Partner {
  height: 70%;
  background: #ffffff;
  opacity: 0.5;
  border-radius: 0 0 0 15px;
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: 1px;
  list-style-type: none;

  .online {
    color: white;
    background: green;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
    box-shadow: 0px 0px 10px 2px greenyellow;
    font-size: 0.8em;
  }

  .offline {
    color: white;
    background: red;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
    font-size: 0.8em;
  }
}

.Partner-mobile {
  height: 70%;
  background: #ffffff;
  opacity: 0.5;
  border-radius: 0 0 0 15px;
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: 1px;
  list-style-type: none;

  .online-mobile {
    color: white;
    background: green;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
    box-shadow: 0px 0px 10px 2px greenyellow;
    font-size: 0.8em;
  }

  .offline-mobile {
    color: white;
    background: red;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
    font-size: 0.8em;
  }
}

ul {
  margin: 0px;
  padding-left: 40px;
}

.PartnerContact {
  padding: 5%;
  color: #000000;
  border-bottom: 1px #000000 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #009688;
    transition: all 0.3s ease-in-out;
  }
}

.PartnerContact-mobile {
  padding: 5%;
  color: #000000;
  border-bottom: 1px #000000 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #009688;
    transition: all 0.3s ease-in-out;
  }
}

.focus {
  background: #009688;
}

.ChatWindow {
  margin: 5%;
  margin-left: 0%;
  background: #009688;
  width: 60%;
  height: 80%;
  border-radius: 15px;
  box-shadow: 5px 5px 15px;
}

.ChatHeader {
  padding: 2%;
  border-radius: 15px 15px 0 0;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}

.Chat {
  padding: 2%;
  height: 80%;
  opacity: 0.8;
  background: #ffffff;
  overflow-y: scroll;
}

.Chat-mobile {
  padding: 2%;
  height: 60vh;
  opacity: 0.8;
  background: #ffffff;
  overflow-y: scroll;
}

.incoming {
  background: #4f83ac;
  width: max-content;
  text-align: left;
}

.outgoing {
  background: #009688;
  width: max-content;
  align-self: flex-end;
  text-align: left;
}

.ChatMessage {
  padding: 1%;
  height: 10%;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-around;
}

.ChatMessage-mobile {
  padding: 1%;
  height: 5%;
  display: flex;
  justify-content: space-around;
}

textarea#ChatBox::-webkit-scrollbar {
  display: none;
}

textarea#ChatBox:focus {
  outline: none;
}

.Avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #ffffff;
    font-size: 1.2em;
  }
}

.ChatName {
  color: #009688;
  margin-left: 2%;
  font-size: 1.3em;
}

.bubble {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  color: #ffffff;
  font-size: 1.2em;
  padding: 10px 10px 10px 12px;
  border-radius: 20px;
  max-width: 80%;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.typing {
  left: 20px;
  width: max-content;

  .bubble {
    background: lighten(#222, 45%);
    padding: 8px 13px 9px 13px;
    display: flex;
    flex-direction: row;
  }
}

.ellipsis {
  width: 10px;
  height: 5px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  align-self: center;
  background: #222;
  border-radius: 50%;
  animation: bounce 1.3s linear infinite;
}

input {
  width: 90%;
  border: 0;
  background: #ffffff;
  color: #009688;
  font-size: 1.2em;
  border-radius: 10px;
  padding: 2%;
}

input::placeholder {
  color: #009688;
}

input:focus {
  color: #222;
  outline: 0;
}

.button {
  border: 0;
  font-size: 1.2em;
  color: #ffffff;
  background: #ffffff00;
  cursor: pointer;
  border-radius: 5px;
  height: max-content;
  display: flex;
  align-self: center;
}

.button:hover {
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: #5f5e5e;
}

.one {
  animation-delay: 0.6s;
}

.two {
  animation-delay: 0.5s;
}

.three {
  animation-delay: 0.8s;
}

@keyframes bounce {
  30% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(0px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}

.PopUp {
  margin-left: 25px;
  margin-right: 25px;
}

.reportUser {
  background: red;
  color: white;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
}

.reportUser-mobile {
  background: red;
  color: white;
  border-radius: 15px;
  padding: 2px;
  font-size: x-small;
  cursor: pointer;
}

.snackbar {
  top: 0;
}
</style>
